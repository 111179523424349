<script>
import { putEditGoods, postAddGoods } from "@/newApi/goods";
import { getParentCate, getChildCate, getExpressSelect } from "@/newApi/commit";
import UploadImage from "@/components/UploadImage/UploadImage";
import Tinymce from "@/components/Tinymce";
export default {
  components: {
    UploadImage,
    Tinymce,
  },
  name: "drawerDetail",
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {
        };
      },
    },
    type: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    couponList_two(val, old) {
      console.log(val, old);
      if (this.ruleForm.category_id && old.length > 0) {
        this.ruleForm.category_id = null;
      }
    },
  },
  data() {
    console.log(this.ruleForm)
    return {
      expressSelect: [], //运费模板下拉选择
      couponList: [], //一级分类列表
      couponList_two: [], //二级分类列表
      fullscreenLoading: false,
      attr_list: [
        {
          attr_name: "", //规格名称
          attr_value: "", //规格值
          attr_price: "", //规格价格
          attr_stock: "", //规格库存
          use_days: "", //使用天数
          discount: "", //折扣
          id: 0,
        },
      ], //商品规格
      attr_value_list: [{ attr_value: "", id: 1 }], //规格值列表
      rules: {
        phar_id: [{ required: true, message: "请选择药房", trigger: "change" }],
        goods_name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        parent_category_id: [
          {
            required: true,
            message: "请选择一级分类",
            trigger: "change",
          },
        ],
        category_id: [
          {
            required: true,
            message: "请选择二级分类",
            trigger: "change",
          },
        ],
     /*   cover_pic: [{ required: true, message: "选择图片", trigger: "change" }],
        desc_pic: [{ required: true, message: "选择图片", trigger: "change" }],*/
      /*  content: [
          { required: true, message: "请输入商品详情", trigger: "blur" },
        ],*/
      },
    };
  },

  //生命周期
  async created() {
    console.log(this.ruleForm,this.options, 2222);
    this.options.forEach(item=>{
      item.phar_id = String(item.value)
    })
    await this._getParentCate();
    await this._getExpressSelect();
    if (this.type == "edit") {
      if (this.ruleForm.attr_list.length > 0) {
        this.attr_list = this.ruleForm.attr_list;
        this.attr_value_list = this.attr_list.map((item, index) => {
          return {
            attr_value: item.attr_value,
            id: index + 1,
          };
        });
        await this._getChildCate(this.ruleForm.parent_category_id);
      }
    }
  },
  methods: {
    //选择药房
    changeYf(e){
      console.log(e,2222)
    },
    _getExpressSelect() {
      getExpressSelect().then((res) => {
        if (res.code == 200) {
          this.expressSelect = res.data;
        }
      });
    },
    //确认按钮
    handleConfirm(formName) {
      let that = this;
      //校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //检查
         /* that.attr_list.forEach((item) => {
            if (item.attr_name == "") {
              that.$message({
                message: "请输入规格名",
                type: "error",
              });
              return false;
            }
            if (item.attr_value == "") {
              that.$message({
                message: "请输入规格值",
                type: "error",
              });
              return false;
            }
            if (item.attr_price == "") {
              that.$message({
                message: "请输入售价",
                type: "error",
              });
              return false;
            }
            if (item.attr_stock == "") {
              that.$message({
                message: "请输入商品数量",
                type: "error",
              });
              return false;
            }
            if (item.use_days == "") {
              that.$message({
                message: "请输入使用天数",
                type: "error",
              });
              return false;
            }
          });*/
          if (that.type == "add") {
            that.ruleForm.attr_list = that.attr_list;
            that._postAddGoods(that.ruleForm);
          } else {
            that.ruleForm.attr_list = that.attr_list;
            that._putEditGoods(that.ruleForm);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    _putEditGoods(data) {
      putEditGoods(data).then((res) => {
        //成功提示
        if (res.code == 200) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    _postAddGoods(data) {
      postAddGoods(data).then((res) => {
        //成功提示
        if (res.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.$emit("getList");
          this.closeDrawer();
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //输入规格名
    blurName() {
      //所有的规格名都改变
      this.attr_list.forEach((item, index) => {
        item.attr_name = this.attr_list[0].attr_name;
      });
    },
    //规格值输入的失焦事件
    blurInput(index) {
      console.log(index);
      this.attr_list[index].attr_value = this.attr_value_list[index].attr_value;
    },
    //规格明细删除或者添加规格值
    addOrDel(type, index) {
      if (type == "add") {
        let id = this.attr_value_list[this.attr_value_list.length - 1].id + 1;
        let attr_name = this.attr_list[0].attr_name || "";
        this.attr_value_list.push({ attr_value: "", id: id });
        this.attr_list.push({
          attr_name: attr_name,
          attr_value: "",
          attr_price: "",
          attr_stock: "",
          discount: "",
        });
      } else {
        //删除指定项
        this.attr_value_list.splice(index, 1);
        this.attr_list.splice(index, 1);
      }
    },
    onSuccessDesc(urlAddr) {
      console.log(urlAddr, "323232");
      this.ruleForm.desc_pic = urlAddr;
      console.log(this.ruleForm);
    },
    onSuccessCover(urlAddr) {
      console.log(urlAddr);
      this.ruleForm.cover_pic = urlAddr;
    },
    //一级分类获取
    _getParentCate() {
      getParentCate().then((res) => {
        this.couponList = res.data;
      });
    },
    //二级分类列表
    _getChildCate(id) {
      console.log(this.ruleForm);
      let info = {
        pid: id,
      };
      getChildCate(info).then((res) => {
        this.couponList_two = res.data;
      });
    },
    //取消
    closeDrawer() {
      let drawers = false;
      this.$emit("closeDrawer", drawers);
    },
  
  },
};
</script>

<template>
  <div class="gg-container" v-loading.fullscreen.lock="fullscreenLoading">
    <div>
      <span style="font-size:23px;border-bottom:3px solid #1684FC;"
        >{{ type == "edit" ? "编辑" : "新增" }}商品</span
      >
    </div>
    <div class="content">
      <div class="top-title">基础信息</div>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="所属药房" prop="phar_id">
        <el-select
          v-model="ruleForm.phar_id"
          placeholder="请选择商品药房"
          size="mini"
          filterable
          clearable
          @change="$forceUpdate(),changeYf()"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="String(item.value)"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称" prop="goods_name">
        <el-input
          v-model="ruleForm.goods_name"
          placeholder="请输入商品名称"
          size="mini"
          class="input"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品分类" required>
        <div style="display: flex;">
          <div style="margin-right:20px">
            <el-form-item prop="parent_category_id">
              <el-select
                v-model="ruleForm.parent_category_id"
                placeholder="请选择一级分类"
                size="mini"
                filterable
                clearable
                @change="
                  _getChildCate(ruleForm.parent_category_id);
                  $forceUpdate()
                "
              >
                <el-option
                  v-for="item in couponList"
                  :key="item.id"
                  :label="item.cate_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="category_id">
              <el-select
                v-model="ruleForm.category_id"
                placeholder="请选择二级分类"
                size="mini"
                filterable
                clearable
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in couponList_two"
                  :key="item.id"
                  :label="item.cate_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="cover_pic" label="商品封面">
        <UploadImage
          :limit="1"
          :url="ruleForm.cover_pic"
          @on-success="onSuccessCover"
        ></UploadImage>
      </el-form-item>
      <el-form-item label="商品图片" prop="desc_pic">
        <div style="wdith:100%;display: flex !important;flex-direction: row;">
          <div style="">
            <UploadImage
              :limit="9"
              :url="ruleForm.desc_pic"
              @on-success="onSuccessDesc"
            ></UploadImage>
          </div>
          <div
            style="width:200px;margin-left:20px;font-size:12px;color:#AAABAA"
          >
            图片建议上传800(宽)*600(高),(上传图片大小不要超过2M),最多上传九张
          </div>
        </div>
      </el-form-item>
      <el-form-item prop="content" label="商品详情">
        <tinymce v-model="ruleForm.content"  :height="200" :width="700" />
      </el-form-item>
      <div class="content">
        <div class="top-title">价格库存</div>
      </div>
      <div style="display: flex;">
        <el-form-item label="商品规格" required>
          <div
            style="padding:20px;border-radius: 20px;background-color: #EFEFEF;"
          >
            <div>
              <span style="margin-right:20px">规格名</span>
              <el-input
                v-model="attr_list[0].attr_name"
                placeholder="请输入商品规格名"
                size="mini"
                class="input"
                @blur="blurName"
              ></el-input>
            </div>
            <div v-for="(item, index) in attr_value_list" :key="item.id">
              <span style="margin-right:20px">规格值</span>
              <el-input
                v-model="item.attr_value"
                placeholder="请输入商品规格值"
                size="mini"
                class="input"
                @blur="blurInput(index)"
              ></el-input>
              <span
                v-if="attr_value_list[index].id != 1"
                style="margin-left:15px;color:red;"
                @click="addOrDel('del', index)"
                >删除</span
              >
              <span
                style="margin-left:15px;color:#1684FC"
                @click="addOrDel('add')"
                >添加规格值</span
              >
            </div>
          </div>
        </el-form-item>
        <el-form-item label="规格明细" required>
          <el-table
            :data="attr_list"
            border
            style="width:700px;z-index:0;margin-top:7px"
            :header-cell-style="{ 'text-align': 'center' }"
            size="mini"
          >
            <el-table-column
              prop="attr_name"
              label="规格名称"
              width="auto"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="attr_value"
              label="规格值名称"
              width="auto"
              align="center"
              height="200"
            >
            </el-table-column>
            <el-table-column
              prop="attr_price"
              label="售价"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-model="scope.row.attr_price"
                    placeholder="请输入售价"
                    size="mini"
                    class="input1"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="attr_stock"
              label="折扣价"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-model="scope.row.discount"
                    placeholder="请输入折扣价"
                    size="mini"
                    class="input1"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="attr_stock"
              label="商品数量"
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>
                  <el-input
                    v-model="scope.row.attr_stock"
                    placeholder="请输入商品数量"
                    size="mini"
                    class="input1"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
						<el-table-column
							prop="use_days"
							label="使用天数"
							width="auto"
							align="center"
						>
							<template slot-scope="scope">
								<div>
									<el-input
										v-model="scope.row.use_days"
										placeholder="请输入使用天数"
										size="mini"
										class="input1"
									></el-input>
								</div>
							</template>
						</el-table-column>
          </el-table>
        </el-form-item>
      </div>
      <el-form-item prop="is_ahead" label="是否预收" required>
        <el-radio
          v-model="ruleForm.is_ahead"
          label="0"
          @change="$forceUpdate()"
          >否</el-radio
        >
        <el-radio
          v-model="ruleForm.is_ahead"
          label="1"
          @change="$forceUpdate()"
          >是</el-radio
        >
        <el-input
          class="input"
          size="mini"
          :disabled="ruleForm.is_ahead == 0"
          v-model="ruleForm.ahead_money"
          @input="$forceUpdate()"
          placeholder="预收金额(选择是为必填)"
        ></el-input>
      </el-form-item>
      <el-form-item class="footer">
        <el-button size="small" @click="closeDrawer">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="handleConfirm('ruleForm')"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped lang="scss">
.input {
  width: 200px;
}
.input1 {
  width: 120px;
  text-align: center !important;
}
.content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  .top-title {
    font-size: 14px;
    font-weight: bold;
    padding: 7px 14px;
    background-color: #efefef;
    border-radius: 10px;
  }
}
.footer {
  position: fixed;
  top: 30px;
  right: 100px;
}
/deep/ .el-form-item {
  margin-bottom: 15px !important;
}
</style>
