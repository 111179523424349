<template>
    <div>
      <!----------------表格---------------->
		<el-table
			border
			size="mini"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			height="625"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="selection"
				width="55"
				align="center"
			/>
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<template
				v-for="(column,index) in tableHead"
			>
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-if="column.field_type === 'textBtn' && column.visible === true"
					show-overflow-tooltip
				>
					<!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
							</div>
							<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="getList('restPage')"
								>
								</el-input>
						</el-popover>
					</template>-->
					<template slot-scope="scope">
						<a style="font-size: 12px; color: #2379fb"
							 @click.prevent="handlePatientDetail(scope.row,column.column_prop)">
							{{scope.row[column.column_prop]}}
						</a>
					</template>
				</el-table-column>
				
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.field_type === 'select' && column.visible === true "
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="click">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch:searchParams[column.column_prop]?true:false,'el-icon-zoom-in':searchParams[column.column_prop]?true:false}"
								/>
							</div>
					
						</el-popover>
					</template>
		
				</el-table-column>
				
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="(column.field_type === 'imageBtn' || column.field_type === 'image') && column.visible === true "
					show-overflow-tooltip
				>
					<template slot-scope="scope">
						<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
								 @click="handleShowImage(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
							<a>点击查看图片</a>
							<el-image
								style="width: 0px; height: 0px"
								:ref="scope.row[column.column_prop][0]"
								:src="imageUrl"
								:preview-src-list="scope.row[column.column_prop]"
								:z-index="9999">
							</el-image>
						</div>
						<div v-else>
							暂无
						</div>
					</template>
				</el-table-column>

				<el-table-column
					:prop="column.column_prop"
					:label="
						column.column_label_user_definition
						? column.column_label_user_definition
						: column.column_label
					"
					:width="column.width ? column.width : ''"
					:key="index"
					align="center"
					v-else-if="column.field_type === 'switch1' && column.visible === true"
					show-overflow-tooltip
					>
					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click"
												v-if="column.is_search">
							<span slot="reference" class="search-header">
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search" :class="{isSearch:searchParams[column.column_prop]?true:false,'el-icon-zoom-in':searchParams[column.column_prop]?true:false}"/>
							</span>
							
							<template v-if="column.search_type === 'select'">
								<el-select
									v-model="searchParams[column.column_prop]"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop)"
							>
									<template slot="prefix">
										<i
											class="el-icon-search"
											style="width: 25px; line-height: 32px;"
										></i>
									</template>
									<el-option
										v-for="item in column.search_data"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</template>

						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
					<template slot-scope="scope">
						<div>
							<el-switch
								v-model="scope.row[column.column_prop]"
								active-color="#13ce66"
								inactive-color="#ff4949"
								:active-value="1"
								:inactive-value="2"
								@change="handleSwitch(column.column_prop,scope.row)"
							>
							</el-switch>
						</div>
					</template>
				</el-table-column>

				<el-table-column
					:prop="column.column_prop"
					:label="
						column.column_label_user_definition
						? column.column_label_user_definition
						: column.column_label
					"
					:width="column.width ? column.width : ''"
					:key="index"
					align="center"
					v-else-if="column.field_type === 'switch' && column.visible === true"
					show-overflow-tooltip
					>

					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click"
												v-if="column.is_search">
							<span slot="reference" class="search-header">
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search" :class="{isSearch:searchParams[column.column_prop]?true:false,'el-icon-zoom-in':searchParams[column.column_prop]?true:false}"/>
							</span>
							
							<template v-if="column.search_type === 'select'">
								<el-select
									v-model="searchParams[column.column_prop]"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop)"
							>
									<template slot="prefix">
										<i
											class="el-icon-search"
											style="width: 25px; line-height: 32px;"
										></i>
									</template>
									<el-option
										v-for="item in column.search_data"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</template>

						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
					<template slot-scope="scope">
						<div>
							<el-switch
								v-model="scope.row[column.column_prop]"
								active-color="#13ce66"
								inactive-color="#ff4949"
								:active-value="1"
								:inactive-value="0"
								@change="handleSwitch(column.column_prop,scope.row)"
							>
							</el-switch>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column
					:sortable="column.is_sort?true:false"
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.visible === true"
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click"
												v-if="column.is_search">
							<span slot="reference" class="search-header">
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search" :class="{isSearch:searchParams[column.column_prop]?true:false,'el-icon-zoom-in':searchParams[column.column_prop]?true:false}"/>
							</span>
							
							<template v-if="column.search_type === 'select'">
								<el-select
									v-model="searchParams[column.column_prop]"
									placeholder="请选择"
									size="mini"
									clearable
									@change="handleSearch(column.column_prop)"
							>
									<template slot="prefix">
										<i
											class="el-icon-search"
											style="width: 25px; line-height: 32px;"
										></i>
									</template>
									<el-option
										v-for="item in column.search_data"
										:key="item.value"
										:label="item.label"
										:value="item.value"
									>
									</el-option>
								</el-select>
							</template>

							<template v-if="column.search_type === 'text'">
								<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams[column.column_prop]"
									clearable
									@change="handleSearch(column.column_prop)"
							>
								</el-input>
							</template>

							<template v-if="column.search_type === 'date'">
								<el-date-picker
									v-model="searchParams[column.column_prop]"
									:default-time="['00:00:00', '23:59:59']"
									type="daterange"
									size="mini"
									value-format="yyyy-MM-dd"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									@change="handleSearch(column.column_prop)">
								</el-date-picker>
							</template>			
						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
				
				</el-table-column>
			</template>
		</el-table>
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'activeTable',
    props: {
        tableHead: {
            type: Array
        },
        tableData:{
            type: Array
        },
		listLoading:{
			type:Boolean
		}
		 
    },
   
    filters:{
		
    },
   
    data(){
      return {
        multipleSelection:[],
        multipleSelectionItem:[],
		imageUrl: '',
		searchParams:{},
		switchValue:0,
      }
    },
    methods: {
		handleSelectionChange (val) {
			this.multipleSelection = [];
			this.multipleSelectionItem = []
			val.forEach((item) => {
				this.multipleSelection.push(item.id);
				this.multipleSelectionItem.push(item);
			});
		},
		//textBtn点击时间
		handlePatientDetail(row,column){
			this.$emit("onTextBtn",{row,column})
		},
		handleSearch(column){
			this.$emit("onSearch",{column,value:this.searchParams[column]})
		},
		//显示图片
		handleShowImage(row, ref) {
			console.log(ref)
			console.log(2222222222222,row)
			console.log(this.$refs[ref])
			this.$refs[ref][0].clickHandler();
			this.imageUrl = row[0]
			console.log(11111111111,this.imageUrl)
		},
		handleSwitch(column,row){
			this.$emit("onSwitch",{column,item:row})
		}
		 
    }
  }
  </script>
  
  <style lang="scss" scoped>
	
  .isSearch{
	  color: #F56C6C;
  }
  .search-icon {
	  color: #F56C6C;
  }
  
  /* switch按钮样式 */
  ::v-deep.switch {
	  .el-switch__label {
		  position: absolute;
		  display: none;
		  color: #fff !important;
	  }
	  
	  /*打开时文字位置设置*/
	  .el-switch__label--right {
		  z-index: 1;
	  }
	  
	  /* 调整打开时文字的显示位子 */
	  .el-switch__label--right span {
		  margin-right: 11px;
	  }
	  
	  /*关闭时文字位置设置*/
	  .el-switch__label--left {
		  z-index: 1;
	  }
	  
	  /* 调整关闭时文字的显示位子 */
	  .el-switch__label--left span {
		  margin-left: 11px;
	  }
	  
	  /*显示文字*/
	  .el-switch__label.is-active {
		  display: block;
	  }
	  
	  /* 调整按钮的宽度 */
	  .el-switch__core, .el-switch__label {
		  width: 55px !important;
		  margin: 0;
	  }
  }
  
  /*抽屉高度*/
  /deep/ .el-drawer {
	  bottom: 0 !important;
  }
  
  /deep/ .el-drawer__header {
	  padding: 0;
	  margin: 0;
  }
  
  /deep/ .el-drawer.rtl {
	  overflow: visible;
	  height: 100vh;
	  bottom: 0;
	  margin-top: 0vh;
	  position: absolute;
  }
</style>
