<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleOperator('edit', multipleSelectionPres[0])"
            >编辑
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleOperator('del', multipleSelectionPres[0])"
            >删除
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                  background-color: #2362FB; border-color: #2362FB"
            @click="handleOperator('add', '')"
            >新增
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                                    background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->

    <activeTable ref="actTable" :tableHead="table_options[optionsName]" :tableData="tableData"
      @onSearch="handleSearch" @onSwitch="handleSwitch" :listLoading="listLoading"></activeTable>

    <Pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <el-drawer
      :append-to-body="true"
      size="76%"
      :visible.sync="dialogTableVisible"
      direction="rtl"
      :wrapperClosable="false"
      :show-close="false"
      custom-class="drawer-class"
    >
      <goodsInfoEditOrAdd
        @closeDrawer="closeDrawer"
        ref="goodsInfoEditOrAdd"
        v-if="dialogTableVisible"
        :ruleForm="ruleForm"
        :type="type"
        :options="pharOptions"
        @getList="getList"
      ></goodsInfoEditOrAdd>
    </el-drawer>
  </div>
</template>

<script>
import goodsInfoEditOrAdd from "./components/goodsInfoEditOrAdd.vue";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import { getAdminFieldIndex } from "@/newApi/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import activeTable from "@/components/activeTable/index"
import {
  getGoodsList,
  editGoodsStatus,
  setRecommendGoods,
  deleteGoods,
  getGoodsInfo,
  setGoodsRecommend
} from "@/newApi/goods";
import { getPharmacyList } from "@/newApi/commit";
import { Message } from "element-ui";
import { mapState } from "vuex";
export default {
  name: "goodsList",
  async created() {
    await this._getAdminFieldIndex();
    await this._getPharmacyList();
    this.getList();
    
  },
  mounted() {
        this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      console.log(res)
      this.operateList = res;
    });
  },
  computed:{
    ...mapState({
      table_options: state => state.user.table_options,
    })

  },
  data() {
    return {
      optionsName:"goodsList",
      table_type:"goodsList",
      operateList: [],
      isActiveEditTableHead: false,
      multipleSelectionPres: [],
      multipleSelection: [],
      activities: [],
      tableHead: [],
      searchTableHead: [
        //控制搜索
        {
          name: "goods_name",
          isSearch: false,
        },
        {
          name: "phar_name",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      fullscreenLoading: false,
      dialogTableVisible: false,
      options: [], //药房列表
      type: "add", //新增或者编辑
      ruleForm: {
        ship_type: 1,
        is_ahead: 0,
      },
      zIndex: 999,
      flag: false,
      pharOptions:[]
    };
  },
  components: {
    Pagination,
    editTableHead,
    goodsInfoEditOrAdd,
    activeTable,
  },

  methods: {
    img() {
      this.flag = true;
    },
    under(pics, refPic = "pic") {
      // console.log(pics,refPic);
      if (pics.length > 0) {
        console.log(123, this.$refs[refPic][0], 596458645);
        this.zIndex = 9999;
        this.$refs[refPic][0].showViewer = true;
      }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    // handleSearch(prop, value) {
    //   console.log(prop, value, "2312343");
    //   this.searchTableHead.forEach((item) => {
    //     if (item.name == prop) {
    //       if (value == "") {
    //         item.isSearch = false;
    //       } else {
    //         item.isSearch = true;
    //       }
    //     }
    //   });
    //   this.getList("restPage");
    // },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "goodsList",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.goodsList;
      //   console.log(this.tableHead, res.data, "* items");
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleSearch(e){
        this.searchParams[e.column] = e.value
        this.getList("restPage")
    },
    _buildSearch(){
        this.table_options[this.optionsName].forEach(item=>{
            if(item.column_prop == "phar_name"){
                item.search_data = this.pharOptions
            }else if(item.column_prop == "is_home" || item.column_prop == "is_new" || item.column_prop == "is_hot"){
              item.search_data = [
                {label:"是",value:1},
                {label:"否",value:0},
              ]
            } else if(item.column_prop == "goods_status"){
              item.search_data = [
                {label:"上架",value:1},
                {label:"下架",value:2},
              ]
            }else{
                item.search_data = []
            }
        })
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
        console.log("getList触发22222");
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.limit = this.listQuery.limit;

      getGoodsList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = Number(_data.per_page);
            this._buildSearch()
            this.tableData = _data.list;
          }
          this.fullscreenLoading = false;
          this.listLoading = false;
        })
        .catch((error) => {
          Message({
            message: error.message,
            type: "error",
          });
          this.listLoading = false;
          this.fullscreenLoading = false;
        });
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    //药房列表
    async _getPharmacyList() {
      const response = await getPharmacyList();
      let data = response;
      if (data.code == 200) {
        this.pharOptions = response.data.map(item => {
            return {
                value:item.id,
                label:item.pharmacy_name
            }
        })
      }
  
    },
    handleSwitch(e){
      let column = e.column
      if (column == "goods_status"){
        this.institutionalStatus(e.item)
      }else if(column == "is_home"){
        this.institutionalStatus1(e.item)
      } else if(column == "is_new" || column == "is_hot"){
        this._setGoodsRecommend(column,e.item)
      }
    },

    _setGoodsRecommend(column,row){
      let params = {
        type:column,
        id:row.id,
        status:row[column]
      }
      setGoodsRecommend(params).then(res => {
        if(res.code == 200){
          return this.$message.success("操作成功")
        }else{
          return this.$message.error(res.msg)
        }
      })
    },
    //推荐商品按钮开关
    institutionalStatus1(row) {
      console.log(row, "row");
      let params = {
        ids: [row.id],
        is_home: row.is_home,
      };
      setRecommendGoods(params).then((response) => {
        let data = response;
        if (data.code == 200) {
          Message({
            message: "成功",
            type: "success",
          });
          // this.getList();
        } else {
          Message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },
    //上传按钮开关
    institutionalStatus(row) {
      console.log(row, "row");
      let params = {
        id: row.id,
        goods_status: row.goods_status,
      };
      editGoodsStatus(params).then((response) => {
        let data = response;
        if (data.code == 200) {
          Message({
            message: "成功",
            type: "success",
          });
          let _data = data.data;
          // this.getList();
        } else {
          Message({
            message: data.msg,
            type: "error",
          });
        }
      });
    },
    _deleteGoods(data) {
      //确认删除
      data = {
        id: [data.id],
      };
      //删除确认框
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteGoods(data).then((response) => {
            let data = response;
            if (data.code == 200) {
              Message({
                message: "删除成功",
                type: "success",
              });
              this.getList();
            } else {
              Message({
                message: data.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //点击编辑详情接口
    async _getGoodsInfo(id) {
      let params = {
        id: id,
      };
      let res = await getGoodsInfo(params);
      if (res.code == 200) {
        console.log(res.data, 2000);
        this.ruleForm.id = res.data.id;
        this.ruleForm.goods_name = res.data.goods_name;
        this.ruleForm.parent_category_id = res.data.parent_category_id;
        this.ruleForm.category_id = res.data.category_id;
        this.ruleForm.desc_pic = res.data.desc_pic;
        this.ruleForm.cover_pic = res.data.cover_pic;
        this.ruleForm.ship_type = res.data.ship_type ? res.data.ship_type : 1;
        this.ruleForm.ship_template_id = res.data.ship_template_id;
        this.ruleForm.is_ahead = res.data.is_ahead ? String(res.data.is_ahead) : '0';
        this.ruleForm.content = res.data.content;
        this.ruleForm.attr_list = res.data.attr_list;
        this.ruleForm.ahead_money = res.data.ahead_money
          ? res.data.ahead_money
          : 0;
        this.ruleForm.phar_id = res.data.phar_id;
      }
    },
    _checkSelection(){
      console.log(4444444444,this.$refs["actTable"].multipleSelectionItem)
        if (this.$refs["actTable"].multipleSelectionItem.length > 1){
            this.$message.error("只能选择一条记录操作")
            return false
        }
        if (this.$refs["actTable"].multipleSelectionItem.length == 0){
            this.$message.error("请选择")
            return false
        }
        return true
    },
    //删除.新增,编辑商品操作
    async handleOperator(type, row) {
      if (type == "del") {
        let resp = this._checkSelection()
        if(!resp) return
        let row = this.$refs["actTable"].multipleSelectionItem[0]
        this._deleteGoods(row);
      } else if (type == "add") {
        this.dialogTableVisible = true;
        this.type = "add";
        this.ruleForm = {
          desc_pic: [],
          is_ahead: "0", //是否预收
          ship_type: "1", //运费类型1统一2区域
        };
      } else if (type == "edit") {
        let resp = this._checkSelection()
        if(!resp) return
        let row = this.$refs["actTable"].multipleSelectionItem[0]
        console.log(111111111,row)
        await this._getGoodsInfo(row.id);
        this.dialogTableVisible = true;
        // this.ruleForm = row;
        this.type = "edit";
      }
    },
    //关闭弹窗,刷新列表
    closeDrawer(e) {
      console.log(e, "e453423");
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/deep/.switch {
  margin-left: 8px;
}

/*打开时文字位置设置*/
/deep/.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
/deep/.switch .el-switch__label--right span {
  margin-left: -2px;
}

/*关闭时文字位置设置*/
/deep/.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
/deep/.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
/deep/.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
/deep/.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 30px;
}
.font-mar {
  margin-right: 20px;
}
/deep/ .drawer-class {
  height: auto !important;
  overflow-y: scroll !important;
}
</style>
